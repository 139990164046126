// Brand colors
$red: #dc3545;
$yellow: #f9c500;
$green: #2BBE3B;
$blue: #1d80c3;
$hoverBlue: #0094d9;
$activeBlue: #4aafe3;
$disabledGrey: #b4b4b4;

$white: #fff;
$black: #000000;
$pumpkin: #ffb441;
$lightGreyBackground: #bbbbbb;
$filterMenuBack: #F0F0F0;
$whiteBackground: #eae9ee;
$greyBorder: #CCCCCC;
$darkGreyBorder: #666666;
$greyText: #9E9E9E;
$darkGreyText: #3d3d3d;
$bodyContentText: #787878; // Generic font color for content/body text
$defaultCornerRadius: 4px; // Generic name for cards, buttons and modal corners
$buttonRadius: 40px;
$blackFormField : rgba(0, 0, 0, 0.42);
$instrumentListTableBorder: #e1e1e1;
$createSupportInquiryBorders: #efefef;
$matChipHover: #ececec;
$buttonBlue: #3e7ebe;
$red-error: #f44336;

// Fonts
$inter: sans-serif;
$dark-grey-font: #585858;

//Exclusive - widget
$headers: #767676;
$icons: #a5a5a5;
$username: #4e4e4e;
$darkgrey: #262626;
$menugrey: #FAFAFA;
$iconGrey: #616161;
$greyback: #d8d8d8;
$widgetgrey: #1E1E1E;
$thinblack: #212121;
$darkestgrey: rgba(0,0,0,0.8);
$darkergrey: rgba(0,0,0,0.87);
$cardDropShadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
$cardBorder: 1px solid #eaeaea;
$navMenuShadow: 0 8px 15px -6px rgba(0, 0, 0, 0.1);
$tableHeaderShadow: 0 2px 3px 0 rgba(0,0,0,0.1);
$menuButtonShadow: 0 3px 7px 0 rgba(0,0,0,0.2);
$widgetButtonShadow: 0 -3px 7px 0 rgba(0,0,0,0.1);
$widgetHeaderShadow: 0 5px 12px 0 rgba(0,0,0,0.08);
$pageHeaderShadow: 0 5px 15px 0 rgba(0,0,0,0.08);
$fabShadow: 0 5px 15px 0 rgba(0,0,0,0.18);
$statusHeaderShadow: inset 0px -3px 18px 0px rgba(0,0,0,0.08);
$modalDropShadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
$whiteModalBackdrop: rgba(255, 255, 255, .8);
$dropDownShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
$greyDropShadow: 0 2px 2px rgba(0, 0, 0, 0.28);
$widgetDrawerShadow: inset 0 0 15px -7px rgba(0, 0, 0, .25);
$greyShadowBottom: -10px 17px 10px -16px #d4d4d4;
$widgetCtaButtonShadow: -5px 0 12px 0 rgba(0, 0, 0, 0.08);
$lightgrey: rgba(248, 248, 248, 1);
$status: #585858;
$cardTitle: #757575;
$darkBlack : rgba(0,0,0,0.87);
$cartBorder : #E5E5E5;
$cartHeading: #EEEEEE;
$labelColor: #666;
$dropDownAlternateBackground: #f3f3f3;
$dropDownHighlightColor: #dcdcdc;
$confirmationCornerRadius: 2px;
$transparentLoadingBackdrop: rgba(255, 255, 255, .75);
$transparentBackground: rgba(0, 0, 0, 0);
$transparentBackdrop: rgba(0, 0, 0, .3);
$darkenHover: rgba(0, 0, 0, .04);

// Layout
$std-container-width: 1050px;
$flexible-container-width: calc(100vw - 75px);
$page-header-height: 110px;
$run-maintenance-div: 516px;
$run-maintenance-card-height: 500px;

$lightGreyBorder: #dcdcdc;
$greyIcon: #666666;
$blueHover: #3392D3;
$blueActive: #66ADDE;
$darkGreyBackground: #333333;
$darkGreyButtonBackground: #9e9e9e;

// Paddings and Margins
$tableHeaderSidePadding: 24px;
$paddingSmall: 8px;
$paddingMedium: 16px;
$paddingLarge: 20px;
$marginSmall: 8px;
$marginMedium: 16px;
$marginLarge: 24px;
$marginXLarge: 32px;
$marginXLargeTopBottom: 32px 0;

// Table Headers
$tableHeaderBackground: #eaeaea;
$tableHeaderPadding: 10px 20px;