@import './variables';
@import './mixins';

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-size: 4.4rem;
  line-height: 5.0rem;
  font-weight: 100;
}

h2 {
  font-size: 2.6rem;
  line-height: 1.15;
  font-weight: 100;
}

p {
  margin: 10px 0;
  line-height: 1.5
}

a { 
  color: $blue;
}

.type {
  &__h1 {
    font-weight: 200;
    font-size: 4.0rem;
    line-height: 1.25;
  }
  &__h1-heavy {
    font-size: 4.4rem;
    line-height: 5.0rem;
    font-weight: 600;
  }
  &__h2 {
    font-weight: 200;
    font-size: 2.8rem;
    line-height: 1.25;
  }
  &__h2--alt {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 2.8rem;
    line-height: 1.25;
    margin-bottom: 20px;
  }
  &__h2--xs {
    font-size: 2.0rem;
    line-height: 1.25;
  }
  &__h3 {
    font-weight: 200;
    font-size: 2.0rem;
    line-height: 1.25;
  }
  &__h3--alt {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 1.25;
  }
  &__h4 {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.42;
    letter-spacing: 0.3px;
  }
  &__pointer {
    cursor: pointer;
  }
  &__p1 {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  &__p2, &__small {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.3px;

    &--wide {
      font-weight: 700;
      letter-spacing: 0.7px;
    }
  }
  &__ul--helix {
    list-style-image: url('/assets/helix-bullet.png');
    padding-left: 19px;
    margin: 18px 0;

    li {
      padding-bottom: 0.8rem;
    }
    li:last-child {
      padding-bottom: 0px;
    }
  }
  &__centered {
    text-align: center;
  }
  &__field-label {
    font-size: 1rem;
    letter-spacing: 0.5px;
    line-height: 1.5;
    text-transform: uppercase;
  }
  &__field-data {
    font-size: 1.4rem;
    line-height: 2.0rem;
  }
  &__widget-header {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: $bodyContentText;
    line-height: 2.0rem;
    letter-spacing: 0.3px;
  }
}

