/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();
mat.$m2-blue-palette: ( 50: #e1f4fc, 100: #b3e2f8, 200: #82cff3, 300: #55bced, 400: #37afea, 500: #24a1e5, 600: #2193d7, 700: #1d80c3, 800: #1a70af, 900: #13508d, A100: #82b1ff, A200: #448aff, A400: #2979ff, A700: #2962ff, contrast: ( 50: black, 100: black, 200: black, 300: black, 400: black, 500: white, 600: white, 700: white, 800: white, 900: white, A100: black, A200: white, A400: white, A700: white, ) );
$candy-app-primary: mat.m2-define-palette(mat.$m2-blue-palette, 700);
$candy-app-theme: mat.m2-define-light-theme((
  color: (
    primary: $candy-app-primary,
    accent:  $candy-app-primary
  )
));

@include mat.all-component-themes($candy-app-theme);
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'sans-serif');
@include mat.all-component-typographies($custom-typography);
@import "./app/shared/styles/variables"; //overwrites
@import "./app/shared/styles/typography";
@import "./app/shared/styles/material";
@import "./app/shared/styles/mixins";

html {
    font-size: 10px;
}

body {
    font-family: $inter;
    font-size: 16px;
    width: 100%;
    background-color: $menugrey;
    margin: unset !important;
    padding: unset !important;
}

a {
  color: $blue;
  text-decoration: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: $blueHover;
    text-decoration: none !important;
    outline: none !important;
  }
}