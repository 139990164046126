@import './variables';
@import './mixins';

$sidenav__link-gutter: 30px;

// This is to remove the scroll-y bar when modals are open:
html.cdk-global-scrollblock {
  overflow-y: auto;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: $blue !important;
}

.mat-mdc-mini-fab {
  background-color: $blue;
}

.mat-mdc-tooltip {
  padding: 5.1% !important;
  font-family: $inter;
  font-size: 0.96em !important;
  background-color: #333333 !important;
  box-shadow: #CCCCCC;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $red !important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
  caret-color: $darkgrey !important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: $red !important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: $red !important;
}

// Overriding red caret in placeholder when invalid
.mat-form-field-invalid {
  .mat-mdc-input-element {
    caret-color: $darkgrey;
  }
}

// Global overrides for all mat cards:
.mat-mdc-card {
  --mdc-outlined-card-container-color: normal;

  box-shadow: $cardDropShadow;
  border: $cardBorder;
}

// Overriding yellow caret in placeholders when focused
.mat-mdc-input-element {
  caret-color: $darkgrey;
  color: $blue;
}

.mat-mdc-menu-panel.filter-menu {
  width: 180px;

  .mat-mdc-menu-content {
    background-color: $menugrey;
    height: 100%;
    width: 100%;

    .menu-controls {
      padding-top: 23px;
      padding-right: 26px;
    }

    .btn-filter {
      height: 40px;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 40px;
      padding: 0 20px;
      margin: 10px 20px;
      border-radius: $buttonRadius;
      box-shadow: 0 3px 7px 0 rgba(0,0,0,0.2);
      color: $darkgrey;
      background-color: $white;

      &.active {
        color: $white;
        background-color: $blue;

        &:focus:not([disabled]),
        &:hover:not([disabled]) {
          background-color: $blue;
        }
      }

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: $white;
      }
    }

    .btn-d {
      text-align: center;
      color: $blue;
      text-transform: uppercase;
      margin-top: 10px;
      font-weight: 500;
      line-height: 20px;
      height: 35px;

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: inherit;
      }
    }
  }
}
//MD-DIALOG

.mat-mdc-menu-panel.tooltip {
  position: relative;
  bottom: 35px;
  left: 80px;
  border-radius: 0px !important;
  background-color: #fafafa;
}
//MD-DIALOG

.mat-mdc-dialog-container.noPadding {
  padding: 0;

  .mat-mdc-dialog-content {
    padding: 0;
    margin: 0;
  }
}

.cdk-overlay-container {
  z-index: 2002;

  &.under {
    z-index: 300;
  }
}

.cdk-overlay-pane {
  max-width: 100vw !important; // This overrides material v7 80vw default max-width
}

.mat-mdc-icon-button {
  --mat-mdc-button-ripple-color: normal;
  --mdc-icon-button-icon-size: 14px !important;

  .mat-mdc-button-persistent-ripple {
      --mat-mdc-button-persistent-ripple-color: normal;
  }
}

.mat-mdc-form-field-icon-suffix {
  align-self: flex-end !important;
}

.mdc-floating-label--required {
  color: $red;
}
